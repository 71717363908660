import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

import { ApiService } from '@common/services/api.service';

import { NavigationUpdatesDto } from './models/navigation-update.dto';
import { NavigationDto } from './models/navigation.dto';

@Injectable({
  providedIn: 'root'
})
export class NavigationDataService {

  constructor(private apiService: ApiService) { }

  public getNavigationData(): Observable<NavigationDto> {
    return this.apiService.query<NavigationDto>('Navigation');
  }

  public getNavigationDataForManagement(): Observable<NavigationDto> {
    return this.apiService.query<NavigationDto>('Navigation/GetForManagement');
  }

  public updateNavigationData(navigationUpdates: NavigationUpdatesDto) {
    return from(this.apiService.command('UpdateNavigation', navigationUpdates));
  }
}
